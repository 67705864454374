


















import { Vue, Component } from 'vue-property-decorator'
import { toastMapper } from '@/store/modules/toast'
import UserAPI from '@/api/requests/user'
import Config from '@/config/default/config'
import { userMapper } from '@/store/modules/user'

const Mappers = Vue.extend({
  methods: {
    ...toastMapper.mapActions(['pushToast']),
    ...userMapper.mapActions(['fetchCurrentUser'])
  }
})

@Component
export default class EmailConfirmation extends Mappers {
  private code = this.$route.query.activationCode
  private textOnPage = 'Ваша почта успешна изменена'

  private async beforeCreate() {
    await UserAPI.logout()
    await this.fetchCurrentUser()
  }
  private async created() {
    if (this.code) {
      await UserAPI.changeMail({
        activationCode: this.code
      })
    } else {
      this.pushToast({
        error: true,
        title: 'Ошибка',
        message: 'Не удалось изменить почту',
        time: 5
      })
      this.$router.push('/error')
    }
  }
  private async onClickDelayedToPage() {
    this.$router.push(Config.routing.authPage.path)
  }
}
